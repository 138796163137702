import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import FirstLoading from "./components/FirstLoading";
import Loader from "./components/Loader";
import { AuthProvider } from "./providers/AuthProvider";
import Routes from "./routes";
const App = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <AuthProvider>
        <FirstLoading />
        <ToastContainer />
        <Routes />
      </AuthProvider>
    </React.Suspense>
  );
};
export default App;
