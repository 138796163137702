import { AuthContext } from "@/providers/AuthProvider";
import { accountActions } from "@/redux/features/account/slice";
import { authSelector } from "@/redux/features/auth/selectors";
import SolanaRpc from "@/utils/solanaRPC";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "./redux";

export default function useGetAddress() {
  const { provider } = useContext(AuthContext);
  const accessToken = useSelector(authSelector.selectAccessToken);
  const dispatch = useDispatch();
  const fetchingAddress = async () => {
    try {
      if (!provider) return;
      const rpc = new SolanaRpc(provider);
      const addresses: string[] = await rpc.getAccounts();
      if (addresses.length) {
        dispatch(
          accountActions.getAddressAccountSuccess({
            address: addresses[0],
          })
        );
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: useGetBalances.ts:17 ~ fetchingBalance ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    fetchingAddress();
  }, [accessToken, provider]);

  return { fetchingAddress };
}
