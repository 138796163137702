import { FC } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import SolanaAdapterProvider from "./components/SolanaAdapterProvider";
import "./index.css";
import { persistor, store } from "./redux";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import MaterialProvider from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const fallbackRender: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  setTimeout(() => {
    resetErrorBoundary();
  }, 5000);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
};

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <MaterialProvider>
      <ErrorBoundary fallbackRender={fallbackRender}>
        <SolanaAdapterProvider>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </SolanaAdapterProvider>
      </ErrorBoundary>
    </MaterialProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
