import { lazy } from "react";
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { lazyRetry } from "./utils/lazyRetry";
const LandingPage = lazy(() => lazyRetry(() => import("./pages/LandingPage")));
const PreSalePage = lazy(() => lazyRetry(() => import("./pages/PreSalePage")));
const StakePage = lazy(() => lazyRetry(() => import("./pages/Stake")));

export const LandingPageRoutes = () => {
  return [
    { path: "/", element: <LandingPage /> },
    { path: "/referral/:code", element: <LandingPage /> },
    { path: "/pre-sale", element: <PreSalePage /> },
    { path: "/stake", element: <StakePage /> },
    { path: "*", element: <Navigate to="/" /> },
  ];
};
const Routes = () => {
  let routes: RouteObject[] = LandingPageRoutes();
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};
export default Routes;
